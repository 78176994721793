import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/Landing.vue";

const routes = ({ appConfig }) => [
  {
    // will match everything
    path: "/:pathMatch(.*)*",
    redirect: () => appConfig.DefaultLandingPage,
  },
  {
    path: "/",
    name: "Landing",
    component: Landing,
  }
];

const router = ({ appConfig }) => {
  const r = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes({ appConfig }),
  });

  return r;
};

export default router;
