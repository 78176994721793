<template>
  <div class="select-site">
    <h1 class="select-site-title">{{ siteTitle }}</h1>
    <div class="select-site-subtitle">
      {{ siteSubTitle }}
    </div>
    <div class="select-site-page-logos">
      <div
        class="select-site-page-logo"
        v-for="(button, index) in LoginButtons"
        :class="{ selected: buttonsSelected[index] }"
        :key="index"
        tabindex="0"
        v-on:keyup.enter="selectSite(index)"
        @click="selectSite(index)"
      >
        <img alt="Logo" :src="button.LogoUrl" />
      </div>
    </div>
    <div class="select-page-continue">
      <button
        class="select-page-continue-button"
        @click="clickContinue"
        v-on:keyup.enter="clickContinue"
        :class="{ disabled: !selected }"
        tabindex="0"
        type="button"
      >
        CONTINUE <i class="la la-angle-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "SelectSite",
  inject: ["appConfig"],
  data() {
    const siteTitle = this.appConfig.SiteTitle;
    const siteSubTitle = this.appConfig.SiteSubTitle;
    const buttonsSelected = this.appConfig.LoginButtons.map(() => false);

    return {
      selected: false,
      siteTitle,
      siteSubTitle,
      buttonsSelected
    };
  },
  methods: {
    clickContinue() {
      if (!this.selected) return;
      window.location = this.selected;
    },
    selectSite(buttonIndex) {
      this.buttonsSelected = this.buttonsSelected.map(
        (button, index) => (button = index === buttonIndex)
      );
      this.selected = this.appConfig.LoginButtons[buttonIndex].TargetUrl;
    },
  },
  setup() {
    const { LogoFile, LoginButtons, Text } = inject("appConfig");
    const router = useRouter();
    const centerText = Text?.en?.LoginWelcome;

    return {
      centerText,
      LogoFile,
      router,
      LoginButtons,
    };
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.select-site {
  height: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.select-site-title {
  color: white;
  font-size: 48px;
}

.select-site-subtitle {
  color: white;
  margin: 40px auto;
  width: 50%;
}

.select-site-page-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.select-site-page-logo {
  margin: 0 48px;
  width: 363px;
  height: 203px;
  background-size: contain;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-shadow: 5px 5px 40px #2c323d8e;
  background: white;
  transition: box-shadow 500ms;
}

.select-site-page-logo img {
  width: 241px;
  height: 100px;
  border-radius: 5px;
}

.select-site-page-logo:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.select-site-page-logo.selected {
  border: 5px solid #00d5fc;
  width: 353px;
  height: 193px;
}

.select-page-continue {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.select-page-continue-button {
  cursor: pointer;
  background: white;
  border-radius: 5px;
  width: 167px;
  height: 55px;
  font-size: 18px;
  color: #354050;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-page-continue-button.disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0);
  border: 1px solid white;
  color: white;
}
</style>
