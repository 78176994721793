import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import configPlugin from "./plugins/appConfig";

async function startup() {
  const response = await fetch('./app-config.json?' + new Date());
  const appConfig = await response.json();
  createApp(App)
    .use(router(appConfig))
    .use(configPlugin, appConfig)
    .mount("#app");
}

startup();