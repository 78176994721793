<template>
  <div class="main-container">
    <div class="main-header"></div>
    <SelectSite />
    <div class="main-footer">
      <div v-for="text in footerText" :key="text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SelectSite from "@/components/SelectSite.vue";

export default {
  name: "Landing",
  components: {
    SelectSite,
  },
  inject: ["appConfig"],
  data() {
    const siteTitle = this.appConfig.SiteTitle;
    const footerText = this.appConfig.Footer.Text;

    document.title = siteTitle;

    return {
      footerText,
    };
  },
};
</script>

<style scoped>
.main-container {
  max-height: 100vh;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-header {
  min-height: 56px;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px solid white;
}

.main-footer {
  width: 100%;
  min-height: 56px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
